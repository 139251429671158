import { template as template_abc165eb2d0c4d9e891b32c0267e1d55 } from "@ember/template-compiler";
const FKText = template_abc165eb2d0c4d9e891b32c0267e1d55(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
