import { template as template_6d696dd1d3604996928f72c45a8b858b } from "@ember/template-compiler";
const FKControlMenuContainer = template_6d696dd1d3604996928f72c45a8b858b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
