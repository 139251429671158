import { template as template_8b26e75caa1f4d8d948faba75b304c35 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_8b26e75caa1f4d8d948faba75b304c35(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
