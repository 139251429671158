import { template as template_bf7fe44e7e024c8b96a449d2f40b4ae8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_bf7fe44e7e024c8b96a449d2f40b4ae8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
